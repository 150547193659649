import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProductionLocationDetails(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/ProductionLocationDetail')
          .then((response)=> { 

            return resolve(response)})
          .catch(error => reject(error))
      })
    },
    fetchProductionLocationTypes(ctx) {
        return new Promise((resolve, reject) => {
          axios
            .get('pamis/admin/organization/ProductionLocationType')
            .then((response)=> { 
  
              return resolve(response)})
            .catch(error => reject(error))
        })
    },
    fetchSites(ctx) {
        return new Promise((resolve, reject) => {
          axios
            .get('pamis/admin/organization/Site')
            .then((response)=> { 
  
              return resolve(response)})
            .catch(error => reject(error))
        })
    },
    fetchWorkCenters(ctx) {
        return new Promise((resolve, reject) => {
          axios
            .get('pamis/admin/organization/WorkCenter')
            .then((response)=> { 
  
              return resolve(response)})
            .catch(error => reject(error))
        })
    },
    fetchProductionKPINames(ctx) {
        return new Promise((resolve, reject) => {
          axios
            .get('pamis/admin/organization/ProductionLocationDetail/ProductionKPINames')
            .then((response)=> { 
  
              return resolve(response)})
            .catch(error => reject(error))
        })
    },
    addProductionLocationDetail(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/organization/ProductionLocationDetail/Create', formData)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },
    updateProductionLocationDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`pamis/admin/organization/ProductionLocationDetail/Update?id=${params.id}`, params)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },
    deleteProductionLocationDetail(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`pamis/admin/organization/ProductionLocationDetail/Delete?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
