import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useProductionLocationDetailList() {
  // Use toast
  const toast = useToast()
  const refProductionLocationDetailListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'name', sortable: true },
    { key: 'value', sortable: true },
    { key: 'productionLocationTypeName', label: 'production Location Type', sortable: true },
    { key: 'productionLocationName', label: 'production Location Name', sortable: true },
    { key: 'isActive' },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalProductionLocationDetails = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const row = ref([])
  const search = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refProductionLocationDetailListTable.value ? refProductionLocationDetailListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value,
      of: totalProductionLocationDetails.value,
    }
  })

  const refetchData = () => {
    refProductionLocationDetailListTable.value.refresh()
    GetProductionLocationDetails()
  }

  watch([currentPage, perPage, search], () => {
    refetchData()
  })

  function GetProductionLocationDetails() {
    store.dispatch('pamis-productionLocationDetail/fetchProductionLocationDetails')
      .then(response => {
        const productResult = response.data.filter(x=>x.isDelete == false);
        row.value = productResult;
        totalProductionLocationDetails.value = productResult.length;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Production Location Detail list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }


  GetProductionLocationDetails()


  return {
    row,
    tableColumns,
    perPage,
    currentPage,
    search,
    totalProductionLocationDetails,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refProductionLocationDetailListTable,
    refetchData
  }
}
